import React, { useState } from 'react';
import background from '../../assets/gradientbackground.png';

function TryRegchat() {

  return (
    <div style={{
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    }} 
    className=" flex items-center justify-center p-12 sm:p-24 ">
  <div className="flex flex-col sm:flex-row w-full  items-center gap-6 px-8 py-12 sm:px-12  bg-white min-w-fit rounded-2xl shadow-lg max-w-6xl">
    <div className="flex flex-col items-start gap-4 w-full">
      <h2 className="text-xl sm:text-2xl font-bold text-black text-left">
        Try RegChat™
      </h2>
      <p className="text-md  text-gray-500 text-left min-w-[300px]">
        Start by logging into or creating an account at RegChat.com. Need help or have suggestions? Our support team
        is just an email away.
      </p>
    </div>
    <div className='flex flex-col w-full gap-4'>
        <div className='flex flex-col md:flex-row gap-4'>
        <button className="px-6 py-3 sm:px-10 sm:py-4 border-2 border-[#191c85] rounded-lg text-[#191c85] hover:bg-[#191c85]/20 transition ease-in duration-50 font-medium w-full text-md"
          onClick={() => window.open('https://regchat.com', '_blank')}
          >
        RegChat.com
      </button>
      <button
  className="px-6 py-3 sm:px-10 sm:py-4 bg-[#191c85] border-2 border-[#191c85] hover:bg-[#191c85]/80 transition ease-in duration-50 rounded-lg text-white font-medium w-full text-nowrap"
  onClick={() => {
    const section = document.getElementById('contact');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }}
>
  Contact Us
</button>

        </div>
      
      <p className="text-xs sm:text-sm text-gray-600 text-center">
      We care about the protection of your data. <a href="https://kenosha.ai/privacy-policy.pdf" target="_blank" className="text-blue-500">Read our Privacy Policy.</a>
      </p>
    </div>
  </div>
</div>

  
);
}

export default TryRegchat;

