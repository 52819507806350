import React from 'react';
import document from "../../assets/document.png";

import icficon from "../../assets/icons/Icon.svg";
import proposalicon from "../../assets/icons/Icon2.svg";
import customicon from "../../assets/icons/Icon3.svg";
import regchaticon from "../../assets/icons/Icon4.svg";



function InformationLong() {

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
<section id="information-long" className="bg-gradient-to-b from-[#DBF0F2] via-[#E3EDFA] to-white">
  <div className=" p-12 md:px-0  lg:px-0  flex flex-col items-center">
    <span className="bg-gradient-to-r tracking-tight from-blue-500 to-teal-400 bg-clip-text text-transparent text-xl md:text-2xl font-bold p-4">Next-Generation Productivity</span>
    <h2 className="my-8 mt-4 p-8 pt-0 text-3xl sm:text-3xl md:text-4xl tracking-tight font-bold text-gray-900 dark:text-white max-w-[650px]">
    Kenosha AI Delivers     <span className="bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent font-bold ">Unprecedented Efficiency </span>
 Improvements With
    </h2>

    <div className="flex items-start">

    <div className="w-full text-left bg-white/50 rounded-3xl p-8">
  <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-2 relative">
    



  <div className='flex flex-col'>
  <h2 className="text-2xl sm:text-3xl font-semibold m-4 sm:m-8 mb-0">Copilots</h2>
  <div className="group transition duration-500 ease-in-out transform rounded-lg sm:rounded-xl p-4 sm:p-8 max-w-sm sm:max-w-xl">
    <img src={icficon} alt="AI-powered Proposal Generation" className="mb-5 w-10 h-10 sm:w-12 sm:h-12" style={{ alignSelf: 'start' }} />
    <h3 className="mb-4 text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">
      Informed Consent Form (ICF) Copilot
    </h3>
    <p className="text-md sm:text-lg text-gray-500 dark:text-gray-400">
      Dramatically increase your margin on Informed Consent Form (ICF) writing services by 80% or more.
    </p>
  </div>
  <div className="group transition duration-500 ease-in-out transform rounded-lg sm:rounded-xl p-4 sm:p-8 max-w-md sm:max-w-xl">
    <img src={proposalicon} alt="Informed Consent Form Generation" className="mb-5 w-10 h-10 sm:w-12 sm:h-12" style={{ alignSelf: 'start' }} />
    <h3 className="mb-4 text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">
      Proposal & Budget Copilot
    </h3>
    <p className="text-md sm:text-lg text-gray-500 dark:text-gray-400">
      Cut your workload in half or more enabling response to more RFIs and RFPs faster and more accurately.
    </p>
  </div>
</div>

{/* Right side */}
<div className='flex flex-col '>
  <h2 className="text-2xl sm:text-3xl font-semibold m-4 sm:m-8 mb-0">GPTs</h2>
  <div className="group transition duration-500 ease-in-out transform rounded-lg sm:rounded-xl p-4 sm:p-8 max-w-sm sm:max-w-xl">
    <img src={customicon} alt="Regulatory Guidance Navigation" className="mb-5 w-10 h-10 sm:w-12 sm:h-12" style={{ alignSelf: 'start' }} />
    <h3 className="mb-4 text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">
      Custom Enterprise GPTs
    </h3>
    <p className="text-md sm:text-lg text-gray-500 dark:text-gray-400">
      Connect your enterprise documents with Kenosha AI to enable conversational AI chat against them for rapid and well-informed answers.
    </p>
  </div>
  <div className="group transition duration-500 ease-in-out transform rounded-lg sm:rounded-xl p-4 sm:p-8 max-w-sm sm:max-w-xl">
    <img src={regchaticon} alt="Regulatory Guidance Navigation" className="mb-5 w-10 h-10 sm:w-12 sm:h-12" style={{ alignSelf: 'start' }} />
    <h3 className="mb-4 text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">
      RegChat™
    </h3>
    <p className="text-md sm:text-lg text-gray-500 dark:text-gray-400">
      Save time on every regulatory question with up-to-date, source linked, and multi-agency summarizations and comparisons.
    </p>
  </div>
</div>
</div>

        {/* <div className="mt-8 text-center">
          <button className="border border-[#D1D5DB] bg-white hover:bg-[#191C85] text-[#191C85] hover:text-white transition ease-in duration-150 rounded-xl py-3 px-8 text-sm sm:text-base font-semibold">
            Request Demo
          </button>
        </div> */}
      </div>
    </div>
  </div>
</section>
  );
}

export default InformationLong;
