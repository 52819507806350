import React from 'react';
import modelnew2 from '../../assets/modelnew2.png';

const scrollToSection = (sectionId) => {
  const section = document.querySelector(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};



function Landing() {
  return (
    <section
    className="py-16 flex flex-col gap-y-8 md:items-center items-start justify-center"
    style={{
      backgroundImage: "url('https://i.imgur.com/ZmATOxR.png')",
      backgroundSize: 'cover',
      backgroundPosition: 'top',
      backgroundColor: '#f8fafc' // Fallback background color
    }}
  >
    
  <div className='flex flex-col gap-y-2 w-full max-w-4xl px-4 '>
    <h1 className="mb-4 text-4xl md:text-6xl font-extrabold tracking-tight leading-none text-left md:text-center dark:text-white">
      Operational AI for the <span className="bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text text-transparent">Clinical Research</span> Industry
    </h1>
    <h2 className='text-lg md:text-xl text-left md:text-center text-slate-600'>
      Kenosha AI is transforming clinical research operations with enterprise-grade AI and ML-driven optimization.
    </h2>
  </div>
  
  <div className='p-8 w-full'>
  <button
    onClick={() => scrollToSection('#contact')}
    className="bg-[#191C85]  hover:bg-[#191C85]/80 text-white hover:text-gray-100 transition ease-in duration-150 rounded-xl py-4 px-8 text-sm sm:text-base font-semibold md:w-fit w-full max-w-[400px]"
  >
    Let's Talk
  </button>

  </div>

    <div className='p-4'>
    <img className="rounded-2xl   w-full max-w-lg  md:max-w-4xl" src={modelnew2} alt="dashboard overview" />

    </div>
  </section>
  
  );
}

export default Landing;
